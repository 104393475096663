import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/store/modules'
import {state} from './state'
import { getters } from './getters'
Vue.use(Vuex);

const store = new Vuex.Store({
    strict: true,
    modules: modules,
    state: state,
    getters: getters
});

export default store;
