export const getters = {
    sale_quality_control_times_history: state => state.sale_quality_control_times_history,
    pagination: state => state.pagination,
    form_initial_data: state => {
        return {
            id: null,
            working_shift: null,
            working_shift_id: null,
            sale: null,
            sale_id: null,
            sale_product: null,
            sale_product_id: null,
        }
    },
    form: state => {
        return {
            id: state.model.id,
            sale_quality_control_id: state.model.sale_quality_control_id,
            sale_product_process: state.model.sale_product_process,
            sale_product_process_id: state.model.sale_product_process ? state.model.sale_product_process.id : null,
            employee: state.model.employee,
            employee_id: state.model.employee ? state.model.employee.id : null,
            machine: state.model.machine,
            machine_id: state.model.machine ? state.model.machine.id : null,            
            bant: state.model.bant,
            bant_id: state.model.bant ? state.model.bant.id : null,
            check_time: state.model.check_time,
            check_time_id: state.model.check_time ? state.model.check_time.id : null,
            checked_quantity: state.model.checked_quantity,
            defect_quantity: state.model.defect_quantity,            
            mistakes: state.model.mistakes,
        }
    }
};
