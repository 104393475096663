import { inventory, scanSaleQRCode, saleProductInventory, scanSaleProductQRCode, saleProductProcessInventory } from '@/api/sales';

export const actions = {
    inventory({ commit }) {
        return new Promise((resolve, reject) => {
            inventory().then(res => {
                commit('SET_INVENTORY', res.data.result.data)
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    scanSaleQRCode({ commit }, sale_id) {
        return new Promise((resolve, reject) => {
            scanSaleQRCode(sale_id).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    saleProductInventory({ commit }, sale_id) {
        return new Promise((resolve, reject) => {
            saleProductInventory(sale_id).then(res => {
                commit('SET_SALE_PRODUCT_INVENTORY', res.data.result.data)
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    scanSaleProductQRCode({ commit }, params) {
        return new Promise((resolve, reject) => {
            scanSaleProductQRCode(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    saleProductProcessInventory({ commit }, sale_product_id) {
        return new Promise((resolve, reject) => {
            saleProductProcessInventory(sale_product_id).then(res => {
                commit('SET_SALE_PRODUCT_PROCESS_INVENTORY', res.data.result.data)
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
}