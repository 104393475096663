import request from '@/utils/request'

export function getInitialDataForQualityControl() {
    return request({
      url: '/saleQualityControls/getInitialDataForQualityControl',
      method: 'get',
    })
}

export function createInitialData(data) {
    return request({
      url: '/saleQualityControls/createInitialData',
      method: 'post',
      data
    })
  }

export function store(data) {
  return request({
    url: '/saleQualityControls',
    method: 'post',
    data
  })
}

export function scanEmployeeQRCode(params) {
  return request({
    url: '/saleQualityControls/scanEmployeeQRCode',
    method: 'get',
    params
  })
}

export function scanMachineQRCode(params) {
  return request({
    url: '/saleQualityControls/scanMachineQRCode',
    method: 'get',
    params
  })
}

export function getEmployeeMistakes(sale_product_process_id) {
  return request({
    url: `/saleQualityControls/getEmployeeMistakes/${sale_product_process_id}`,
    method: 'get',
  })
}

export function getSaleQualityControlTimesHistory(params) {
  return request({
    url: '/saleQualityControls/getSaleQualityControlTimesHistory',
    method: 'get',
    params
  })
}

export function getSaleQualityControlTimesHistoryOfEmployee(params) {
  return request({
    url: '/saleQualityControls/getSaleQualityControlTimesHistoryOfEmployee',
    method: 'get',
    params
  })
}