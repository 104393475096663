const TokenKey = 'auth_access_token';
const UserName = 'auth_name';

// set data
export function setToken(token) {
    return localStorage.setItem(TokenKey,token)
}
export function setName(name) {
    return localStorage.setItem(UserName,name)
}

// get data
export function getToken() {
    return localStorage.getItem(TokenKey)
}
export function getName() {
  return localStorage.getItem(UserName)
}

// remove data
export function removeToken() {
    return localStorage.removeItem(TokenKey)
}
export function removeName() {
    return localStorage.removeItem(UserName)
}