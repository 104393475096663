import {login, logout, getAuth} from "@/api/auth";
import {getToken, setToken, removeToken} from "@/utils/auth";

export const actions = {
    login({ commit }, loginData) {
        return new Promise((resolve, reject) => {
            login(loginData).then(response => {
                const { data } = response.data.result;
                if (data.token) {
                    commit('SET_TOKEN', data.token)
                    commit('SET_NAME', data.name)
                                        
                    setToken(data.token)
                    resolve()
                }
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getAuth({commit,state},payload){
        return new Promise((resolve, reject) => {
            getAuth(state.token).then(res => {
              const { data } = res.data.result

              if (!data) {
                reject('Проверка не удалась, пожалуйста, войдите снова.')
              }

              const { role, name } = data

              if (!role) {
                reject('Роль не может быть пустой')
              }

              commit('SET_ROLE', role)
              commit('SET_NAME', name)
              resolve(data)
            }).catch(error => {
              reject(error)
            })
          })
    },

    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                removeToken()
                commit('REMOVE_TOKEN')
                commit('SET_ROLE', '')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetToken({ commit }) {
        return new Promise((resolve) => {
            removeToken()
            commit('REMOVE_TOKEN')
            resolve()
        })
    },
}
