export const getters = {
    sale_check_times_history: state => state.sale_check_times_history,
    bant_inventory: state => state.bant_inventory,
    pagination: state => state.pagination,
    form_initial_data: state => {
        return {
            id: null,
            working_shift: null,
            working_shift_id: null,
            sale: null,
            sale_id: null,
            sale_product: null,
            sale_product_id: null,
        }
    },
    form: state => {
        return {
            id: state.model.id,
            sale_check_id: state.model.sale_check_id,
            sale_product_process: state.model.sale_product_process,
            sale_product_process_id: state.model.sale_product_process ? state.model.sale_product_process.id : null,
            is_first_check_of_process: false,
            start_time: state.model.start_time,
            bant_id: state.model.bant_id,
            check_time: state.model.check_time,
            check_time_id: state.model.check_time ? state.model.check_time.id : null,            
            sale_check_employees: state.model.sale_check_employees,
            meto_numbers: state.model.meto_numbers,
            measurement_time: '', // TODO:: olib tashash
        }
    }
};
