import axios from 'axios'
import store from './../store/index'
import router from './../router/index'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + '/refa/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = "Bearer " + store.getters.token
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // if the custom code is not 200, it is judged as an error.
    if (response.status !== 200) {
      return Promise.reject(response)
    } else {
      return Promise.resolve(response)
    }
  },
  error => {
      // 500: Unexpected error; 401: Unuthenticated;
      let response = error.response;
      if (response.status === 401) {
        store.dispatch('auth/resetToken')
        router.push('/login').catch(() => {});
      }
    return Promise.reject(error)
  }
);

export default service
