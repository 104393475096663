import request from '@/utils/request'

export function inventory() {
    return request({
      url: '/sales/inventory',
      method: 'get',
    })
}

export function scanSaleQRCode(sale_id) {
  return request({
    url: `/sales/scanSaleQRCode/${sale_id}`,
    method: 'get',
  })
}

export function saleProductInventory(sale_id) {
  return request({
    url: `/sales/saleProductInventory/${sale_id}`,
    method: 'get',
  })
}

export function scanSaleProductQRCode(params) {
  return request({
    url: `/sales/scanSaleProductQRCode`,
    method: 'get',
    params
  })
}

export function saleProductProcessInventory(sale_product_id) {
  return request({
    url: `/sales/saleProductProcessInventory/${sale_product_id}`,
    method: 'get',
  })
}