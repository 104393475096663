export const model = {
    id: null,    
    sale_quality_control_id: null,
    sale_product_process: null,
    employee: null,
    machine: null,
    bant: null,
    check_time: null,
    checked_quantity: 10,
    defect_quantity: 0,
    mistakes: [
        {
            mistake_id: null,
            mistake_name: null,
        }
    ],
};