import { getInitialDataForCheck, createInitialData, store, checkIsFirstCheckOfProcess, scanEmployeeQRCode, scanMachineQRCode, checkMetoNumbersAttachedToBant, getSaleCheckTimesHistory, getSaleCheckTimesHistoryOfEmployee, createSaleDisruption, getBantInventory } from '@/api/saleChecks';

export const actions = {
    getInitialDataForCheck({ commit }) {
        return new Promise((resolve, reject) => {
            getInitialDataForCheck().then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    createInitialData({ commit }, data) {
        return new Promise((resolve, reject) => {
            createInitialData(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    checkIsFirstCheckOfProcess({ commit }, params) {
        return new Promise((resolve, reject) => {
            checkIsFirstCheckOfProcess(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    scanEmployeeQRCode({ commit }, params) {
        return new Promise((resolve, reject) => {
            scanEmployeeQRCode(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    scanMachineQRCode({ commit }, params) {
        return new Promise((resolve, reject) => {
            scanMachineQRCode(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    checkMetoNumbersAttachedToBant({ commit }, params) {
        return new Promise((resolve, reject) => {
            checkMetoNumbersAttachedToBant(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getSaleCheckTimesHistory({ commit }, params) {
        return new Promise((resolve, reject) => {
            getSaleCheckTimesHistory(params).then(res => {
                commit("SET_SALE_CHECK_TIMES_HISTORY", res.data.result.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getSaleCheckTimesHistoryOfEmployee({ commit }, params) {
        return new Promise((resolve, reject) => {
            getSaleCheckTimesHistoryOfEmployee(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    createSaleDisruption({ commit }, data) {
        return new Promise((resolve, reject) => {
            createSaleDisruption(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getBantInventory({ commit }) {
        return new Promise((resolve, reject) => {
            getBantInventory().then(res => {
                commit('SET_BANT_INVENTORY', res.data.result.data)
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    incrementPaginationPage({ commit }) {
      commit('INCREMENT_PAGINATION_PAGE')
    },
}
