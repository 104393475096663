import { inventory } from '@/api/workingShifts';

export const actions = {
    inventory({ commit }) {
        return new Promise((resolve, reject) => {
            inventory().then(res => {                
                commit('SET_INVENTORY', res.data.result.data)
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
}