import Vue from 'vue'

/**
 * Element UI
 */
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU'
Vue.use(ElementUI, { locale });
/**
 * end element ui
 */

/**
 * For alert
 */
import { notify } from './index'
Vue.prototype.$alert = notify;


/**
 * Lodash
 */
window._ = require('lodash');


/**
 * Gloabl mixin
 */
import { mapGetters } from 'vuex';

Vue.mixin({
    data() {
        return {
            date_format: 'yyyy-MM-dd',
            date_time_format: 'yyyy-MM-dd HH:mm',
        }
    },
    computed: {
        ...mapGetters({
            auth_name: 'name',
            role: 'role'
        })
    },
    methods: {
        
    },
});
