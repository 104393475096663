export const mutations = {
    SET_MODEL: (state, model) => {
        
    },
    SET_SALE_QUALITY_CONTROL_TIMES_HISTORY: (state, data) => {
        let {quality_control_times} = data
        let {pagination} = data
        if( pagination.current_page == 1 )
        {
          state.sale_quality_control_times_history = [];
        }
        
        state.sale_quality_control_times_history = [...state.sale_quality_control_times_history, ...quality_control_times];
    },

    
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    INCREMENT_PAGINATION_PAGE: (state) => {        
        state.pagination.page++
    },
    EMPTY_SET_SALE_QUALITY_CONTROL_HISTORY: (state) => {
        state.sale_quality_control_times_history = [];
        state.pagination.page = 1;
    },
};
