export const ru_messages = {
    'yes': 'Да',
    'cancel': 'Отменить',
    'Login': 'Авторизоваться',
    'password': 'Пароль',
    'Exit': 'Выход',
    'REFA': 'РЕФА',
    'Back': 'Назад',
    'save': 'Сохранить',
    'Working shift': 'Смена',
    'Not selected': 'Не выбрано',
    'close': 'Закрыть',
    'sale': 'Производство',
    'product': 'Продукт',
    'Result': 'Результат',
    'Required data not selected or not saved': 'Необходимые данные не выбраны или не сохранили',
    'Scan QR code': 'Сканировать QR код',
    'Process': 'Процесс',
    'Start time of work': 'Время начало работы',
    'Last check': 'Последняя проверка',
    'at': 'в',
    'Check': 'Проверка',
    'Employee': 'Сотрудник',
    'Machine': 'Станок',
    'Add employee': 'Добавить сотрудник',
    'Bant': 'Линия',
    'Bant of machines does not match': 'Линия станков не совпадает',
    'METO': 'МЕТО',
    'Add': 'Добавить',
    'REFA History': 'История REFA',
    'Load more': 'Загрузить больше',
    'Measurement time': 'Время измерения',
    'loading': 'Загрузка...',
    'Quality control': 'Контроль качества',
    'Mistakes': 'Ошибки',
    'Process not selected': 'Процесс не выбрано',
    'Number of verified': 'Количество проверенных',
    'Not passed OTK': 'Кол-во непрошедших',
    'Control history': 'История Контроль',    
    'Start_time': 'Время начала',
    'Start_Off': 'Время окончания',
    'Sale disruption': 'Сбой',
    'For all': 'Для всех',
    'Comment': 'Комментарий',
}
