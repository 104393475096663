import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainLayout",
    component: () => import("@/layouts/MainLayout.vue"),
    redirect: {
      name: "MainLayout",
    },
    children: [
      {
        path: "/",
        name: "MainLayoutHome",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "login/",
        name: "MainLayoutLogin",
        component: () => import("@/views/login/index.vue"),
      },
      {
        path: "scan",
        name: "MainLayoutScan",
        component: () => import("@/components/scan.vue"),
      },
    ],
  },

  {
    path: "/",
    name: "AuthLayout",
    component: () => import("@/layouts/AuthLayout.vue"),
    redirect: {
      name: "AuthLayoutAbout",
    },
    children: [
      {
        path: "control",
        name: "AuthLayoutControl",
        component: () => import("@/views/control/index.vue"),
      },
      {
        path: "controlHistory",
        name: "AuthLayoutControlHistory",
        component: () => import("@/views/controlHistory/index.vue"),
      },
      {
        path: "refa",
        name: "AuthLayoutRefa",
        component: () => import("@/views/refa/index.vue"),
      },
      {
        path: "refaHistory",
        name: "AuthLayoutRefaHistory",
        component: () => import("@/views/refaHistory/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
