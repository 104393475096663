import { getInitialDataForQualityControl, createInitialData, store, scanEmployeeQRCode, scanMachineQRCode, getEmployeeMistakes, getSaleQualityControlTimesHistory, getSaleQualityControlTimesHistoryOfEmployee } from '@/api/saleQualityControls';

export const actions = {
    getInitialDataForQualityControl({ commit }) {
        return new Promise((resolve, reject) => {
            getInitialDataForQualityControl().then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    createInitialData({ commit }, data) {
        return new Promise((resolve, reject) => {
            createInitialData(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },    

    scanEmployeeQRCode({ commit }, params) {
        return new Promise((resolve, reject) => {
            scanEmployeeQRCode(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    scanMachineQRCode({ commit }, params) {
        return new Promise((resolve, reject) => {
            scanMachineQRCode(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getEmployeeMistakes({ commit }, sale_product_process_id) {
        return new Promise((resolve, reject) => {
            getEmployeeMistakes(sale_product_process_id).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getSaleQualityControlTimesHistory({ commit }, params) {
        return new Promise((resolve, reject) => {
            getSaleQualityControlTimesHistory(params).then(res => {
                commit("SET_SALE_QUALITY_CONTROL_TIMES_HISTORY", res.data.result.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getSaleQualityControlTimesHistoryOfEmployee({ commit }, params) {
        return new Promise((resolve, reject) => {
            getSaleQualityControlTimesHistoryOfEmployee(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    incrementPaginationPage({ commit }) {
      commit('INCREMENT_PAGINATION_PAGE')
    },
}
