export const model = {
    id: null,    
    sale_check_id: null,
    sale_product_process: null,
    start_time: '',
    bant_id: null,
    check_time: null,
    sale_check_employees: [
        {
            employee_id: null,
            employee: null,
            machine_id: null,
            machine: null,
            bant_id: null,
            bant: null,
            sale_check_times_history: [],
        }
    ],
    meto_numbers: [
        {
            current_meto_number: '',
        }
    ],
};