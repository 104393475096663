import request from '@/utils/request'

export function getInitialDataForCheck() {
    return request({
      url: '/saleChecks/getInitialDataForCheck',
      method: 'get',
    })
}

export function createInitialData(data) {
    return request({
      url: '/saleChecks/createInitialData',
      method: 'post',
      data
    })
  }

export function store(data) {
  return request({
    url: '/saleChecks',
    method: 'post',
    data
  })
}

export function checkIsFirstCheckOfProcess(params) {
  return request({
    url: '/saleChecks/checkIsFirstCheckOfProcess',
    method: 'get',
    params
  })
}

export function scanEmployeeQRCode(params) {
  return request({
    url: '/saleChecks/scanEmployeeQRCode',
    method: 'get',
    params
  })
}

export function scanMachineQRCode(params) {
  return request({
    url: '/saleChecks/scanMachineQRCode',
    method: 'get',
    params
  })
}

export function checkMetoNumbersAttachedToBant(data) {
  return request({
    url: '/saleChecks/checkMetoNumbersAttachedToBant',
    method: 'post',
    data
  })
}

export function getSaleCheckTimesHistory(params) {
  return request({
    url: '/saleChecks/getSaleCheckTimesHistory',
    method: 'get',
    params
  })
}

export function getSaleCheckTimesHistoryOfEmployee(params) {
  return request({
    url: '/saleChecks/getSaleCheckTimesHistoryOfEmployee',
    method: 'get',
    params
  })
}

export function createSaleDisruption(data) {
  return request({
    url: '/saleChecks/createSaleDisruption',
    method: 'post',
    data
  })
}

export function getBantInventory() {
  return request({
    url: '/saleChecks/getBantInventory',
    method: 'get',
  })
}