<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: "Home",
    data() {
        return {};
    },
};
</script>

</style>
